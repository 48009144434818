<template>
  <v-card elevation="10">
    <v-card-title class="text-h5">
      {{ $t('plot.export_data') }}
    </v-card-title>

    <v-divider />

    <v-card-title class="mb-2 grey lighten-2">
      <v-row>
        <v-col>
          <v-checkbox
            v-for="(item, index) in selectedPhase"
            :key="index"
            v-model="selectedItems"
            :value="item"
            :label="item"
          />
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider />

    <v-card-actions>
      <v-spacer />
      <v-btn
        color="#f4c020"
        text
        @click="closeDownload"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        text
        :disabled="selectedItems == 0"
        @click="setAggregates"
      >
        {{ $t('plot.download') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import Papa from 'papaparse'

export default {
  name: 'DTExportPlotDataForm',
  props: {
    timeSpan: { type: Object, default: null },
    consolidatedPlots: { type: Object, default: null },
    selectedPhase: { type: Object, default: null },
    action: { type: String, default: '' }
  },

  data () {
    return {
      selectedItems: [],
      dataResult: []
    }
  },

  watch: {
    selectedItems () {
      return this.selectedItems.length
    }
  },

  methods: {
    closeDownload () {
      this.selectedItems = []
      this.$emit('closeDownload')
    },

    setAggregates () {
      this.dataResult = []
      const outputResult = []
      let timeEntry = []
      let valueEntry = []

      this.selectedItems.sort()
      this.selectedItems.forEach(element => {
        const result = this.consolidatedPlots.filter((consolidated) => consolidated.Plot === element)
        this.dataResult = [
          ...this.dataResult,
          result
        ]
      })

      this.dataResult.forEach(plotData => {
        const aggregations = {}
        plotData.forEach(entry => {
          if (!aggregations[entry.aggregation]) {
            aggregations[entry.aggregation] = {
              time: [],
              value: []
            }
          }

          timeEntry = entry.time.split(',').map(Number)
          aggregations[entry.aggregation].time.push(...timeEntry)

          valueEntry = entry.value.split(',').map(Number)
          aggregations[entry.aggregation].value.push(...valueEntry)
        })

        Object.keys(aggregations).forEach(aggregation => {
          const times = Array.from(new Set(aggregations[aggregation]?.time)).sort((a, b) => a - b)
          times.forEach(time => {
            const entry = {
              Plot: plotData[0].Plot,
              aggregation,
              time: time.toString(),
              value: aggregations[aggregation].value[times.indexOf(time)] || 0
            }
            outputResult.push(entry)
          })
        })
      })

      outputResult.sort((a, b) => parseInt(a.time) - parseInt(b.time))
      this.transformToCSVFormat(outputResult)
    },

    transformToCSVFormat (inputData) {
      const csvOutput = []

      const uniqueAggregations = Array.from(new Set(inputData.map(entry => entry.aggregation)))
      const distributedTime = Array.from(new Set(inputData.map(entry => entry.time))).sort((a, b) => a - b)

      distributedTime.forEach(time => {
        const date = new Date(time * 1000)
        const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
        const entry = { time: formattedDate }

        uniqueAggregations.forEach(aggregation => {
          const matchingEntry = inputData.find(e => e.aggregation === aggregation && e.time === time)
          entry[`Plot${aggregation}`] = matchingEntry ? matchingEntry.value.toString() : '0'
        })

        csvOutput.push(entry)
      })

      this.exportPlot(csvOutput)
      this.closeDownload()
    },

    exportPlot (data) {
      const csvData = Papa.unparse(data, {
        quotes: false,
        header: true
      })

      const blob = new Blob([csvData], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.href = url
      a.download = 'measurement_plots' + '.csv'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }

  }
}
</script>
